import logoLink from '../resources/ber.png';

export const cssVariables = {
    active: '#00A0B9',
    inactive: '#E61900',

    primary_light: '#80D0DC',
    primary: '#00A0B9',
    primary_dark: '#008094',
    text_on_primary: '#fff',
    primary_transparent: '#ccecf1',

    secondary: '#E61900',
    secondary_light: '#f38c80',
    text_on_secondary: '#fff',
    secondary_transparent: '#fad1cc',

    font_color: 'black',
    link_color: '#00A0B9',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#00A0B9',
    activate_color: '#D2D5D7',
    deactivate_color: '#D2D5D7',
    delete_color: '#E61900',

    dropdown_background: '#80D0DC',

};

export const logo = logoLink;
